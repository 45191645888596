<template>
  <div class="row">
    <div class="col-md-12 px-7">
      <div class="row py-3 px-4" id="filter-card">
        <form @submit.prevent="filterResult" class="w-100">
          <div class="col-lg-12 col-xl-12 px-0">
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-lg-6 col-md-6 col-sm-12 my-2 row px-0 mx-0 d-flex justify-content-start items-center" >
                <input-filter
                    :helper-text="$t('general.search')"
                    title="general.search"
                    :placeholder="$t('general.search_filter')"
                    :model.sync="filters.search"
                ></input-filter>
              </div>
              <div class="col-lg-5 col-md-5 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                      :helper-text="$t('general.start_date')"
                      title="general.start_date"
                      :model.sync="filters.start_date"
                      name="start_time">
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                      :helper-text="$t('general.end_date')"
                      title="general.end_date"
                      :startDate="filters.start_date"
                      :model.sync="filters.end_date"
                      name="end_date">
                  </date-filter>
                </div>
              </div>
              <div
                  class="col-xl-1 my-lg-1 col-md-2 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                  style="margin: 0 !important;">
                <div class="col-12 pr-0">
                  <button @click="filterResult(null)"
                          class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1">{{
                      $t('general.filter')
                    }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button @click="resetFilters"
                          class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1">
                    {{ $t('general.clear') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-md-12">
      <dashboard-box>
        <template v-slot:preview>
          <div class="row px-10 py-4">
              <h2 v-if="false" class="text-center py-10 col-12">
                {{ $t('sample.you_have_no_task')}}
              </h2>
              <template v-if="tasks.length > 0">
                <div v-if="Number(task.status_id)" class="col-xl-3 col-lg-4 col-md-6 col-sm-12 py-3" v-for="task in tasks">
                  <TaskCard
                      @click="onClickCard"
                      :task="task"
                  ></TaskCard>
                </div>
              </template>
            <template v-if="tasks.length === 0">
              <div class="w-100 mt-5">
                <h3 class="text-center">{{ $t('model_house.no_task')}}</h3>
              </div>
            </template>

            </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapMutations, mapGetters } from 'vuex'
import TaskIndexCard from "@/view/pages/samples/layout/task/TaskIndexCard";
import InputFilter from "@/assets/components/filters/InputFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import Paginate from "@/assets/components/dataTable/Pagination";
import moment from "moment";
import {GET_TASKS, RESET_FILTERS} from "@/core/services/store/sample/task/task-index.module";
import TaskCard from "@/view/pages/model-house/layout/TaskCard";
import {GET_ITEMS, LOADING} from "@/core/services/store/REST.module";
import {LARAVEL_DATE_FORMAT} from "@/core/config/constant";
import {MODEL_HOUSE_TASK_START_DATE_STORAGE_NAME, MODEL_HOUSE_TASK_END_DATE_STORAGE_NAME} from "@/core/storage/storage-names"

export default {
  name: "ModelHouseTasks",
  components: {
    TaskCard,
    DashboardBox,
    TaskIndexCard,
    InputFilter,
    DateFilter,
    Paginate
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
    }),
  },
  data() {
    return {
      filters: {
        search: null,
        per_page: 99999,
        start_date: moment().subtract(30, 'd'),
        end_date: moment().add(1, 'd')
      },
      tasks: [],
    };
  },
  methods: {
    resetFilters() {
      this.filters = {
        search: null,
        start_date: moment().subtract(30, 'd'),
        end_date: moment().add(1, 'd')
      }
    },
    filterResult(){
      if (this.isUserGranted('ModelHouse', ['tasks', 'setTask', 'update'])) {
        localStorage.setItem(MODEL_HOUSE_TASK_START_DATE_STORAGE_NAME, moment(this.filters.start_date));
        localStorage.setItem(MODEL_HOUSE_TASK_END_DATE_STORAGE_NAME, moment(this.filters.end_date));

        let tempFilters = {...this.filters}, self = this;
        tempFilters.start_date = tempFilters.start_date.format(LARAVEL_DATE_FORMAT);
        tempFilters.end_date = tempFilters.end_date.format(LARAVEL_DATE_FORMAT);
        this.$store.dispatch(GET_ITEMS, {
          url: 'api/model-house/tasks',
          acceptPromise: true,
          filters: tempFilters,
          showLoading: true,
        }).then((result) => {
          if(result.status){
            self.tasks = self.formatTasks(result.data);
          }
        })
      }
    },
    formatTasks(data){
      for(let i = 0; i < data.data.length; i++){
        data.data[i].process_date = moment(data.data[i].process_date, LARAVEL_DATE_FORMAT);
      }
      return data.data;
    },
    onClickCard(payload){
      if (payload.task.status.id === 7) {
        this.$router.push({name: 'model_house.task', params: { id: payload.task.sample_id}})
      } else {
        this.$router.push({name: 'model_house.task.action', params: {id: payload.task.sample_id, model_house_task_status: payload.task.status.id, model_house_id: payload.task.id}})
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('model_house.model_house'), route: "model_house.index" },
      { title: this.$t('model_house.tasks') }
    ]);

    if (this.isUserGranted('ModelHouse', ['tasks', 'setTask', 'update'])) {
      this.filterResult();
    }
    this.filters.end_date = localStorage.hasOwnProperty(MODEL_HOUSE_TASK_START_DATE_STORAGE_NAME)
      ? moment(localStorage.getItem(MODEL_HOUSE_TASK_START_DATE_STORAGE_NAME))
      : moment().add(1, 'd').format(LARAVEL_DATE_FORMAT);

    this.filters.start_date = localStorage.hasOwnProperty(MODEL_HOUSE_TASK_END_DATE_STORAGE_NAME)
      ? moment(localStorage.getItem(MODEL_HOUSE_TASK_END_DATE_STORAGE_NAME))
      : moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT);
  }
}
</script>

<style scoped>

</style>
